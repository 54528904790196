$(document).on("turbolinks:load", function () {
  if ($("#users-training-overview-table").length > 0) {
    $(document).on("change", "#q_sections_id_eq", function () {
      let section_id = $(this).val();
      if (section_id.length > 0) {
        $.ajax({
          url: `/${determineControllerPath(window.location.href)}/reports/filter_section_category_dropdowns`,
          method: "GET",
          dataType: "json",
          data: { section_id: section_id },
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            //// These lines resets the child values to avoid incongruous behavior
            if ($("#q_categories_id_eq").val().length > 0) {
              $("#q_categories_id_eq")[0].selectize.setValue(null, true);
            }
            getAndFilterCategories(response);
          },
        });
      }
    });

    $(document).on("change", "#q_categories_id_eq", function () {
      let category_id = $(this).val();
      if (category_id.length > 0) {
        $.ajax({
          url: `/${determineControllerPath(window.location.href)}/reports/filter_section_category_dropdowns`,
          method: "GET",
          dataType: "json",
          data: { category_id: category_id },
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            setSection(response);
            getAndFilterCategories(response);
          },
        });
      }
    });
  }
});

function setSection(response) {
  let section = response["section"];
  let section_dropdown = $("#q_sections_id_eq")[0].selectize;
  section_dropdown.setValue(section[0], true);
}

function setCategory(response) {
  let category = response["category"];
  let category_dropdown = $("#q_categories_id_eq")[0].selectize;
  category_dropdown.setValue(category[0], true);
}

function getAndFilterCategories(response) {
  let categories = response["categories"];
  let category_dropdown = $("#q_categories_id_eq")[0].selectize;
  category_dropdown.clearOptions();
  $(categories).each(function () {
    category_dropdown.addOption({ value: this[0], text: this[1] });
  });
}
