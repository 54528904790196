$(document).on("turbolinks:load", function () {
  if ($("#usage_report_search").length > 0) {
    $(document).on("change", "#organisation", function () {
      console.log("here");
      let organisation_id = $(this).val();
      if (organisation_id.length > 0) {
        $("#users_teams").removeClass("is-hidden");
        $.ajax({
          url: `/filter_users_dropdowns`,
          method: "GET",
          dataType: "json",
          data: { organisation_id: organisation_id },
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            //// These lines resets the child values to avoid incongruous behavior
            if ($("#team").val().length > 0) {
              $("#team")[0].selectize.setValue(null, true);
            }
            getAndFilterTeams(response);
          },
        });
      }
    });

    $(document).on("change", "#team", function () {
      let team_id = $(this).val();
      if (team_id.length > 0) {
        $.ajax({
          url: `/filter_users_dropdowns`,
          method: "GET",
          dataType: "json",
          data: { team_id: team_id },
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            setOrganisation(response);
            getAndFilterTeams(response);
          },
        });
      }
    });
  }
});

function setOrganisation(response) {
  let organisation = response["organisation"];
  let organisation_dropdown = $("#organisation")[0].selectize;
  organisation_dropdown.setValue(organisation[0], true);
}

function getAndFilterTeams(response) {
  let teams = response["teams"];
  let teams_dropdown = $("#team")[0].selectize;
  teams_dropdown.clearOptions();
  $(teams).each(function () {
    teams_dropdown.addOption({ value: this[0], text: this[1] });
  });
}

window.updateUsernameSearchFieldAndSubmit = function (link) {
  const username = link.dataset.username;

  const usernameField = document.querySelector(
    'input[name="q[u_username_cont]"]',
  );

  if (usernameField) {
    usernameField.value = username;

    const searchButton = document.querySelector(
      'input[type="submit"][value="Search"]',
    );
    if (searchButton) {
      searchButton.click();
    }
  }
};

window.updateSpecificRecordSearchFieldAndSubmit = function (link) {
  const title = link.dataset.title;
  const id = link.dataset.id;
  const content_type = link.dataset.contentType;

  const titleField = document.querySelector(
    'input[name="q[c_content_title_cont]"]',
  );
  const idField = document.querySelector('input[name="q[content_id_eq]"]');
  const contentTypeField = document.querySelector(
    'select[name="q[content_type_eq]"]',
  );

  if (titleField && idField && contentTypeField) {
    titleField.value = title;
    idField.value = id;
    contentTypeField.selectize.setValue(content_type);

    const searchButton = document.querySelector(
      'input[type="submit"][value="Search"]',
    );
    if (searchButton) {
      searchButton.click();
    }
  }
};
