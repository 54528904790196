let starterJSON = [];
let compareArray = [];
let changesMade = false;

// getStartJson called on load and on AJAX success (as starting statuses will have to update to the present changes)
$(document).on("turbolinks:load", function () {
  if ($("#users_training_overview_table").length > 0) {
    getContent();
  }
});

window.showOverlay = function () {
  $(".loading-overlay").show();
};

window.windowGetContent = function () {
  getContent();
};

function getContent() {
  showOverlay();
  var dataToSend = [];
  $("#users_training_overview_table div[id]").each(function () {
    var id = $(this).attr("id");
    var parts = id.split("_");

    if (parts.length === 2) {
      dataToSend.push({
        assessment_id: parts[0],
        user_id: parts[1],
      });
    }
  });
  $.ajax({
    url: $("#data_path").val(),
    method: "POST",
    data: { data: dataToSend },
    dataType: "json",
    async: true,
    success: function (response) {
      if (response.status === "success") {
        Object.entries(response.tests).forEach(([key, test_data]) => {
          determineStyle(key, test_data);
        });
      }
    },
    complete: function (response) {
      $(".loading-overlay").hide();
    },
  });
}

function determineStyle(key, test_data) {
  const element = $(`#${key}`);
  if (element.length) {
    if (test_data.result == "Pass") {
      // if test passed, change to green and put in expiry date
      element.css("background-color", "#47c78e");
      element.html(`
                      <p>${test_data.expiry_date}</p>
                  `);
    } else if (test_data.result == "Fail") {
      // if test failed, change to red
      element.css("background-color", "#ff6684");
    } else if (test_data.result == null) {
      // if test is null, user hasnt started it yet
      element.css("border", "1px solid grey");
      if (test_data.mandatory) {
        element.css("background-color", "#fff");
      } else {
        // change to grey if not mandatory
        element.css("background-color", "#ebebeb");
      }
    } else {
      // test is in progress, change to amber and display retakes if applicable - dont show the word "in progress"
      if (test_data.result != "In progress") {
        element.html(`
                        <p>${test_data.result}</p>
                    `);
      }
      element.css("background-color", "#ffb71a");
    }
    if (test_data.overdue) {
      //mark as red if overdue
      element.html(`
                      <p>${test_data.expiry_date}</p>
                  `);
      element.css("background-color", "#ff6684");
    }
  }
}
