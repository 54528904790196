// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "stylesheets/application.scss";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");

// After installing a 3rd party JS library through Yarn we need it to be included in webpacker
// Instructions of how to do this are dependent on each JS library and should be in their getting started docs
// Webpacker/ES6 also treats each JS file as its own scope / seperate module
// apparently this is good for de-cluttering the global scope
// this means you need to import a 3rd party library into each JS file you want to use it in
// or you can import it here and make it available in a global variable so it can be used everywhere (helpful for a library you will use everywhere like jQuery)
global.$ = require("jquery");
require("jquery-ui");
require("jquery-ui-touch-punch");
require("@nathanvda/cocoon");

import ClipboardJS from "clipboard";
global.ClipboardJS = ClipboardJS;
import tippy from "tippy.js";
global.tippy = tippy;
import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel.min.js";
global.bulmaCarousel = bulmaCarousel;

require("javascript/clear_search");
require("javascript/password_strength");
require("javascript/burger_menu");
require("javascript/classie");
require("javascript/disable_link");
require("javascript/delete_notification");
require("javascript/rich_action");
require("javascript/file_upload");
require("javascript/user_form");
require("javascript/flatpickr");
require("javascript/bulma_collapsible");
require("javascript/select_multiple");
require("javascript/selectize_dropdown");
require("javascript/modal");
require("javascript/sortable");
require("javascript/remove_attachment");
require("javascript/content_overview");
require("javascript/training_overview");
require("javascript/content_association");
require("javascript/membership_templates");
require("javascript/checkbox_show");
require("javascript/assessments_summary");
require("javascript/custom_trix_config");
require("javascript/checkbox_selects");
require("javascript/tooltip");
require("javascript/assessment_parameters");
require("javascript/generic_shared");
require("javascript/dashboard_quicklinks");
require("javascript/copy_to_clipboard");
require("javascript/masquerade");
require("javascript/checkbox_toggle");
require("javascript/procedure_carousel");
require("javascript/notification");
require("javascript/help_text");
require("javascript/sortable_rows");
require("javascript/fill_search");
require("javascript/mark_as_read");
require("javascript/nmc_animation");
require("javascript/cropper");
require("javascript/import_users");
require("javascript/countdown_timer");
require("javascript/quicklink_toggle");
require("javascript/access_options");
require("javascript/select_company");
require("javascript/my_performance");
require("javascript/show_users");
require("javascript/mfa");
require("javascript/cookies");
require("javascript/loading_spinner");

// base
require("javascript/base/assessments/ajax_form");
require("javascript/base/users/table_search");
require("javascript/base/users/form");
require("javascript/base/users/transfer_management");
require("javascript/base/reports/recent_hits");
require("javascript/base/reports/content_usage");
require("javascript/base/reports/training_overview");
require("javascript/base/membership_templates/index");
require("javascript/base/content_management/question_banks/confirmation");
require("javascript/base/content_management/question_banks/form");
require("javascript/base/content_management/question_banks/index");
require("javascript/base/content_management/procedures/index");
require("javascript/base/content_management/questions_assessments_search");
require("javascript/base/content_management/procedures/links_in_new_tab");
require("javascript/base/content_management/procedures/main_content");
require("javascript/base/content_management/assessments/dropdowns");
require("javascript/base/content_management/assessments/membership_template_relation");
require("javascript/base/content_management/assessments/question_banks/questions/manage_questions.js");
